import Swiper from "~/node_modules/swiper/swiper-bundle.min.js";

window.addEventListener("DOMContentLoaded", () => {
    // Sliders
    new Swiper(".videos-swiper", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    });
    
    new Swiper(".testimonials-swiper", {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        autoplay: true,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        }
    });

    new Swiper(".vlog-swiper", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    });

    new Swiper(".publications-swiper", {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        autoplay: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        }
    });

    // Navigation
    const header = document.querySelector(".c-header");
    window.addEventListener("hashchange", () => {
        scroll(window.location.hash, header);
    });
    if(window.location.hash) {
        scroll(window.location.hash, header);
    }

    // Functions
    function scroll(hash, header) {
        let section = document.querySelector(hash);
        if(section != null) {
            window.scrollTo(0, section.offsetTop - header.clientHeight);
        }
    }
});
